import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import NotificationStyled from './styled'

import HumanIncidenceImgSrc from '../../assets/images/notification/human.svg'
import VehicleIncidenceImgSrc from '../../assets/images/notification/vehicle.svg'
import CloseImgSrc from '../../assets/images/notification/close.svg'
import SuccessImgSrc from '../../assets/images/notification/success.svg'

export const HUMAN_INCIDENCE = 'human'
export const VEHICLE_INCIDENCE = 'vehicle'
const VISITOR_UPDATE = 'visitor_update'
const TENANT_UPDATE = 'tenant_update'
const MEMBER_UPDATE = 'member_update'
const USER_UPDATE = 'user_update'
const URL_UPDATE = 'url_update'

class Notification extends React.PureComponent {
  getNotificationIcon() {
    let imgSrc
    switch (this.props.incidenceType) {
      case HUMAN_INCIDENCE: {
        imgSrc = HumanIncidenceImgSrc
        break
      }

      case VEHICLE_INCIDENCE: {
        imgSrc = VehicleIncidenceImgSrc
        break
      }

      case USER_UPDATE:
      case VISITOR_UPDATE:
      case URL_UPDATE:
      case TENANT_UPDATE:
      case MEMBER_UPDATE: {
        imgSrc = SuccessImgSrc
        break
      }
      default: {
        imgSrc = ''
        break
      }
    }
    return (
      <div id={`${this.props.id}-img`} data-test={`${this.props.id}-img`} className="notification-icon-container">
        <img src={imgSrc} />
      </div>
    )
  }

  getNotificationContent() {
    const messageClass =
      this.props.incidenceType === VISITOR_UPDATE ||
      this.props.incidenceType === TENANT_UPDATE ||
      this.props.incidenceType === MEMBER_UPDATE ||
      this.props.incidenceType === USER_UPDATE ||
      this.props.incidenceType === URL_UPDATE
        ? 'notification-detail-container white'
        : 'notification-detail-container'
    return (
      <div className="notification-content-container">
        <div className="notification-title-container">{this.props.title}</div>
        <div className={messageClass}>{this.props.message}</div>
        {this.props.timestamp ? <div className="notification-time-container">{moment(this.props.timestamp).fromNow()}</div> : ''}
      </div>
    )
  }

  handleOnClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  getCloseBtn() {
    let output = null
    if (this.props.closeToast) {
      output = (
        <div
          id={`${this.props.id}-close-btn`}
          data-test={`${this.props.id}-close-btn`}
          className="notification-close-btn"
          onClick={this.props.closeToast}
        >
          <img src={CloseImgSrc} />
        </div>
      )
    }
    return output
  }

  render() {
    let { className } = this.props
    className += this.props.onClick ? ' cursor-pointer' : ''
    className += this.props.isRead ? ' read' : ''
    return (
      <NotificationStyled id={this.props.id} data-test={this.props.id} className={className} onClick={this.handleOnClick}>
        {this.getNotificationIcon()}
        {this.getNotificationContent()}
        {this.getCloseBtn()}
      </NotificationStyled>
    )
  }
}

Notification.defaultProps = {
  id: 'notification',
  className: '',
  title: '',
  message: '',
  isRead: false,
}

Notification.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  incidenceType: PropTypes.oneOf([HUMAN_INCIDENCE, VEHICLE_INCIDENCE, VISITOR_UPDATE, TENANT_UPDATE, MEMBER_UPDATE, USER_UPDATE, URL_UPDATE])
    .isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timestamp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  isRead: PropTypes.bool,
  closeToast: PropTypes.func,
}

export default Notification
