import styled from 'styled-components'

export default styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 304px;
  height: 80px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #242424;
  border-bottom: 1px solid #4a4a4a;
  &.read {
    background-color: #1e1e1e;
  }
  .notification-icon-container {
    display: flex;
    width: 48px;
    height: 48px;
    margin: 0 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .notification-content-container {
    flex: 1;
    width: 100%;
    .notification-title-container {
      font-size: 14px;
      font-weight: 500;
      color: white;
    }
    .notification-detail-container {
      font-size: 12px;
      font-weight: normal;
      color: #981214;
      &.white {
        color: white;
      }
    }
    .notification-time-container {
      font-size: 12px;
      font-weight: normal;
      color: #999999;
    }
  }
  .notification-close-btn {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    width: 16px;
    height: 16px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
